import { handleResponse } from "./handle-response";
import store from "../../store/index";

export const nodeService = {
  getNodeList,
  getNodeListCreate,
  getNodeDetail,
  addNode,
  editNode,
  removeNodes,
  startNodes,
  stopNodes,
  getAllNodeList,

  // 日志
  getLog,
  getLogType,

  getMonitor,
  // getNodeCerts,

  assignNode,
  // 升级
  getNodeUpdateHistory,
  nodeUpdateFirmware,
  getNodeCanUpdateFirmware,
  getUpgradeFirmwares,
  startVnc,
  stopVnc,
  reStartVnc,
  getVncInfo,

  getSSHInfo,
  startSSH,
  stopSSH,
  reStartSSH,
  interruptOSUpdate,

  removeLicence,
  nodeLicencesBath,
  algorithmGroupLicencesBath,
  nodeLicences,
  algorithmGroupLicences,
  getHistoryLicences,
  getLicences,
  startResetOS,
  getNodeAlgorithmsUpgradeHistory,
  addNodeAlgorithmsUpgrade,
  deleteNodeAlgorithmsUpgrade,
  addNodeAlgorithmsBathUpgrade,
  startLogUpload,
  stopLogUpload,
  getUserUpgradeDebs,
  nodeUserDebUpgrade,
  getUserDebUpgradeHistory,
  getNodeUserDebList,
  getUserDebNodes,
  getLogCollectionTasks,
  addLogCollectionTasks,
  getOSState
};
function getAllNodeList(name, uuid, labelIds, firmwareId) {
  return fetch(
    `/v1/console/nodes/list?name=${name || ""}&uuid=${uuid || ""}&labelIds=${
      labelIds || ""
    }&firmwareId=${firmwareId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${store.getState().authToken}`,
      },
    }
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function getNodeList(
  name,
  productModel,
  labelIds,
  tenantId,
  state,
  company,
  stationId,
  province,
  city,
  district,
  limit,
  offset
) {
  return fetch(
    `/v1/console/nodes?name=${name || ""}&productModel=${
      productModel || ""
    }&userId=${tenantId || ""}&state=${state || ""}&labelIds=${
      labelIds || ""
    }&companyId=${company || ""}&seriesNumber=${stationId || ""}&province=${
      province || ""
    }&city=${city || ""}&district=${district || ""}&limit=${
      limit || 10
    }&offset=${offset || 1}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${store.getState().authToken}`,
      },
    }
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function getNodeListCreate(
  name,
  productModel,
  labelIds,
  tenantId,
  state,
  company,
  stationId,
  province,
  city,
  district,
  limit,
  offset
) {
  return fetch(
    `/v1/console/nodes?name=${name || ""}&productModel=${
      productModel || ""
    }&userId=${tenantId || ""}&state=${state || "RUNNING"}&labelIds=${
      labelIds || ""
    }&companyId=${company || ""}&seriesNumber=${stationId || ""}&province=${
      province || ""
    }&city=${city || ""}&district=${district || ""}&limit=${
      limit || 10
    }&offset=${offset || 1}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${store.getState().authToken}`,
      },
    }
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function getNodeDetail(id) {
  return fetch(` /v1/console/nodes/${id}`, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
/**
 * 获取最新版本字段
 * @param {*} id
 * @returns
 */
function getUpgradeFirmwares(id) {
  return fetch(`/v1/console/nodes/${id}/upgrade/firmwares`, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function addNode(name, description, seriesNumber, labels, userId) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      name: name,
      description: description,
      seriesNumber: seriesNumber,
      labelIds: labels,
      userId,
    }),
  };
  return fetch(`/v1/console/nodes`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
/**
 * 节点分配
 */
function assignNode(nodeIds, userId) {
  const requestOptions = {
    method: "PUt",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      userId,
      nodeIds,
    }),
  };
  return fetch(`/v1/console/nodes/user`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function editNode(id, name, desc, labels, seriesNumber, userId, address) {
  const requestOptions = {
    method: "PUt",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      name: name,
      description: desc,
      labelIds: labels,
      seriesNumber,
      userId,
      address,
    }),
  };
  return fetch(`/v1/console/nodes/${id}`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function removeNodes(ids) {
  return fetch(`/v1/console/nodes/${ids}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function startNodes(id) {
  return fetch(`/v1/console/nodes/${id}/enable`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function stopNodes(id) {
  return fetch(`/v1/console/nodes/${id}/disable`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function getLog(id, startTime, endTime, type) {
  console.log(startTime);
  console.log(endTime);
  return fetch(
    `/v1/console/nodes/${id}/logs?startTime=${startTime || ""}&endTime=${
      endTime || ""
    }&type=${type || ""}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store.getState().authToken}`,
      },
    }
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function getLogType() {
  return fetch(`/v1/console/nodes/log/types`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function getMonitor(id, startTime, endTime) {
  return fetch(
    `/v1/console/nodes/${id}/stats?startTime=${startTime || ""}&endTime=${
      endTime || ""
    }`,
    {
      // return fetch(`/v1/console/nodes/${id}/stats?startTime=${decodeURIComponent(startTime) || ''}&endTime=${decodeURIComponent(endTime)  || ''}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${store.getState().authToken}`,
      },
    }
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

// function getNodeCerts(id, limit, offset) {
//     return fetch(` /v1/console/nodes/${id}/certs?limit=${limit}&offset=${offset}`, {
//         headers: {
//             'Content-Type': 'application/json;charset=UTF-8',
//             Authorization: `Bearer ${store.getState().authToken}`
//         }
//     }).then(handleResponse).then(res => {
//         return res;
//     })
// }

// function getNodeStats(id, limit, offset, startTime, endTime) {
//     return fetch(` /v1/console/nodes/${id}/stats?limit=${limit}&offset=${offset}&${startTime}&${endTime}`, {
//         headers: {
//             'Content-Type': 'application/json;charset=UTF-8',
//             Authorization: `Bearer ${store.getState().authToken}`
//         }
//     }).then(handleResponse).then(res => {
//         return res;
//     })
// }

// function getNodeUpdate(id) {
//     return fetch(` /v1/console/nodes/${id}/update`, {
//         headers: {
//             'Content-Type': 'application/json;charset=UTF-8',
//             Authorization: `Bearer ${store.getState().authToken}`
//         }
//     }).then(handleResponse).then(res => {
//         return res;
//     })
// }

// function getNodeUpdates(id) {
//     return fetch(` /v1/console/nodes/${id}/upgrade/firmwares`, {
//         headers: {
//             'Content-Type': 'application/json;charset=UTF-8',
//             Authorization: `Bearer ${store.getState().authToken}`
//         }
//     }).then(handleResponse).then(res => {
//         return res;
//     })
// }

function getNodeUpdateHistory(id, page, pageSize) {
  return fetch(
    `/v1/console/nodes/${id}/upgrades?limit=${pageSize}&offset=${page}`,
    {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${store.getState().authToken}`,
      },
    }
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function nodeUpdateFirmware(nodeId, firmwareId) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      firmwareId: firmwareId,
    }),
  };
  return fetch(`/v1/console/nodes/${nodeId}/upgrade`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function getNodeCanUpdateFirmware(id) {
  return fetch(`/v1/console/nodes/${id}/upgrade/firmwares`, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
/**
 * 获取远程运维信息
 * @param {*} id
 * @returns
 */
function getVncInfo(id) {
  return fetch(`/v1/console/nodes/${id}/vnc`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
/**
 * 启动远程运维
 * @param {*} id
 * @returns
 */
function startVnc(id) {
  return fetch(`/v1/console/nodes/${id}/vnc/start`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
/**
 * 停止远程运维
 * @param {*} id
 * @returns
 */
function stopVnc(id) {
  return fetch(`/v1/console/nodes/${id}/vnc/stop`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
/**
 * 重启远程运维
 * @param {*} id
 * @returns
 */
function reStartVnc(id) {
  return fetch(`/v1/console/nodes/vnc/${id}/restart`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

/**
 * 3.5.37.获取远程SSH信息
 * @param {*} id
 * @returns
 */
function getSSHInfo(id) {
  return fetch(`/v1/console/nodes/${id}/ssh`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

/**
 * 3.5.38.启动远程SSH
 * @param {*} id
 * @returns
 */
function startSSH(id) {
  return fetch(`/v1/console/nodes/${id}/ssh/start`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function startResetOS(id) {
  return fetch(`/v1/console/nodes/${id}/resetOS`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function interruptOSUpdate(uuid) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      uuid,
      "result":"01",//00 成功 01 失败
      "reason":"发送失败"
    }),
  };
  return fetch(`/v1/station/OSUpgradeResult`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
/**
 * 系统升级进度查询
 * @param {*} id
 * @returns
 */
function getOSState(id) {
  return fetch(`/v1/console/nodes/${id}/OSState`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
/**
 * 3.5.39.停止远程SSH
 * @param {*} id
 * @returns
 */
function stopSSH(id) {
  return fetch(`/v1/console/nodes/${id}/ssh/stop`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

/**
 * 重启远程SSH
 * @param {*} id
 * @returns
 */
function reStartSSH(id) {
  return fetch(`/v1/console/nodes/ssh/${id}/restart`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
/**
 * 节点算法授权Licence查询
 * @param {*} id
 * @returns
 */
function getLicences(id) {
  return fetch(`/v1/console/nodes/${id}/licences`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
/**
 * 节点历史算法授权Licence列表
 * @param {*} id
 * @returns
 */
function getHistoryLicences(id) {
  return fetch(`/v1/console/nodes/${id}/licences/history`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
/**
 * 节点算法授权
 * @param {*} nodeId
 * @param {*} algorithms
 * @returns
 */
function nodeLicences(nodeId, algorithms) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      algorithms: algorithms,
    }),
  };
  return fetch(`/v1/console/nodes/${nodeId}/licences`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
/**
 * 节点算法组授权
 * @param {*} nodeId
 * @param {*} algorithmGroups
 * @returns
 */
function algorithmGroupLicences(nodeId, algorithmGroups) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      algorithmGroups: algorithmGroups,
    }),
  };
  return fetch(
    `/v1/console/nodes/${nodeId}/algorithm/group/licences`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
/**
 * 节点算法批量授权
 * @param {*} nodeIds
 * @param {*} algorithms
 * @returns
 */
function nodeLicencesBath(nodeIds, algorithms) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      nodeIds,
      algorithms,
    }),
  };
  return fetch(`/v1/console/nodes/licences/bath`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
/**
 * 节点算法组批量授权
 * @param {*} nodeIds
 * @param {*} algorithms
 * @returns
 */
function algorithmGroupLicencesBath(nodeIds, algorithmGroups) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      nodeIds,
      algorithmGroups,
    }),
  };
  return fetch(
    `/v1/console/nodes/algorithm/group/licences/bath`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

/**
 * 节点算法授权Licence删除
 * @param {*} licencesId  algorithmId
 * @returns
 */
function removeLicence(licencesId, algorithmId) {
  return fetch(`/v1/console/nodes/licences/${licencesId}/${algorithmId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//节点算法升级记录查询
function getNodeAlgorithmsUpgradeHistory(nodeId, pageSize, page) {
  return fetch(
    `/v1/console/nodes/${nodeId}/local/upgrade?limit=${pageSize || 10}&offset=${
      page || 1
    }`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${store.getState().authToken}`,
      },
    }
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//添加节点可升级算法
function addNodeAlgorithmsUpgrade(nodeId, firmwareId) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      firmwareId: firmwareId,
    }),
  };
  return fetch(`/v1/console/nodes/${nodeId}/local/upgrade`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//删除节点可升级算法
function deleteNodeAlgorithmsUpgrade(id) {
  return fetch(`/v1/console/nodes/local/upgrade/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//批量添加节点可升级算法
function addNodeAlgorithmsBathUpgrade(nodeIds, firmwareId) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      nodeIds,
      firmwareId,
    }),
  };
  return fetch(`/v1/console/nodes/local/upgrade/bath`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
//开启系统日志上传
function startLogUpload(nodeId) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  };
  return fetch(`/v1/console/nodes/${nodeId}/logs/start`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
//关闭系统日志上传
function stopLogUpload(nodeId) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  };
  return fetch(`/v1/console/nodes/${nodeId}/logs/stop`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//3.5.28.获取节点用户软件包可升级版本
function getUserUpgradeDebs(id) {
  return fetch(`/v1/console/nodes/${id}/upgrade/debs`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//3.5.29.节点用户软件升级
function nodeUserDebUpgrade(id, debId) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      debId,
    }),
  };
  return fetch(`/v1/console/nodes/${id}/userDeb/upgrade`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//3.5.30.查询用户软件更新历史
function getUserDebUpgradeHistory(id, limit, offset) {
  return fetch(
    `/v1/console/nodes/${id}/userDeb/upgrades?limit=${limit || 10}&offset=${
      offset || 1
    }`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${store.getState().authToken}`,
      },
    }
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//3.5.31.查询节点用户软件
function getNodeUserDebList(id) {
  return fetch(`/v1/console/nodes/${id}/userDeb/list`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//3.5.32.查询用户软件包可升级节点
function getUserDebNodes(name, seriesNumber, labelIds, debId) {
  return fetch(
    `/v1/console/nodes/list?name=${name || ""}&seriesNumber=${
      seriesNumber || ""
    }&labelIds=${labelIds || ""}&debId=${debId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${store.getState().authToken}`,
      },
    }
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

//3.5.41.查询日志采集任务
function getLogCollectionTasks(nodeId) {
  return fetch(`/v1/console/nodes/${nodeId}/log/collection/tasks`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
//3.5.42.新增日志采集任务
function addLogCollectionTasks(nodeId, startTime, endTime) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      startTime: startTime || "",
      endTime: endTime || "",
    }),
  };
  return fetch(
    `/v1/console/nodes/${nodeId}/log/collection/tasks`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
