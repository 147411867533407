import { handleResponse } from "./handle-response";
import store from "../../store/index";

export const firmwareService = {
  getFirmwareByOta,
  getFirmwareDetail,
  removeFirmware,
  addFirmware,
  editFirmware,

  startFirwareVersion,
  stopFirwareVersion,

  getFirmwareSoftwareList,
  getFirmwareSoftwareDetail,
  addFirmwareSoftware,
  editFirmwareSoftware,
  removeFirmwareSoftware,

  getAllFirmwareByOta,
  uploadDeb,
  getDebsList,
  releaseFirmware,
  removeFirwareVersion,
  getFirmwareByOsPack,
  removeOsPack,
  addOsPack,
  upgradeOspack,
  resetNodeState
};
function getAllFirmwareByOta(productId, name, version) {
  return fetch(`/v1/console/products/${productId}/firmwares/list?name=${name || ""}&version=${version || ""}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function getFirmwareByOta(productId, name, version, offset, limit, isPrivate) {
  return fetch(`/v1/console/products/${productId}/firmwares?name=${name || ""}&version=${version || ""}&isPrivate=${isPrivate}&offset=${offset || 1}&limit=${limit || 20}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function getFirmwareByOsPack(productId, name, version, offset, limit, isPrivate) {
  return fetch(`/v1/console/products/${productId}/osPack/page?name=${name || ""}&version=${version || ""}&isPrivate=${isPrivate}&offset=${offset || 1}&limit=${limit || 20}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function getFirmwareDetail(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  };
  return fetch(`/v1/console/products/firmwares/${id}`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function removeFirmware(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  };
  return fetch(`/v1/console/products/firmwares/${id}`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function removeOsPack(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  };
  return fetch(`/v1/console/products/osPack/${id}`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function addFirmware(prodcutId, name, version, description, isPrivate) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      name: name || "",
      version: version || "",
      description: description || "",
      isPrivate: isPrivate || 0,
    }),
  };
  return fetch(`/v1/console/products/${prodcutId}/firmwares`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function addOsPack(prodcutId, name, version, updateInfo, id) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      id:id || "",
      name: name || "",
      version: version || "",
      updateInfo: updateInfo || "",
    }),
  };
  return fetch(`/v1/console/products/${prodcutId}/osPack`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function upgradeOspack(id, osId) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      osId:osId || "",
    }),
  };
  return fetch(`/v1/console/nodes/${id}/upgradeOS`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function resetNodeState(id) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    // body: JSON.stringify({
    //   osId:osId || "",
    // }),
  };
  return fetch(`/v1/console/nodes/${id}/resetNodeState`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function editFirmware(id, name, version, description, url, isPrivate) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      name: name || "",
      version: version || "",
      description: description || "",
      url: url || "",
      isPrivate: isPrivate || 0,
    }),
  };
  return fetch(`/v1/console/products/firmwares/${id}`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function startFirwareVersion(id) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  };
  return fetch(`/v1/console/products/firmwares/${id}/enable`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
/**
 * 下架
 * @param {*} id
 * @returns
 */
function removeFirwareVersion(id) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  };
  return fetch(`/v1/console/products/firmwares/${id}/remove`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function stopFirwareVersion(id) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  };
  return fetch(`/v1/console/products/firmwares/${id}/disable`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

// function getFirmwareSoftwareList(firmwareId, name, version, offset, limit) {
//     return fetch(`/v1/console/firmwares/${firmwareId}/softwares?name=${name || ''}&version=${version || ''}&offset=${offset || 1}&limit=${limit || 20}`, {
//         method: 'GET',
//         headers: {
//             'Content-Type': 'application/json;charset=UTF-8',
//             Authorization: `Bearer ${store.getState().authToken}`
//         }
//     }).then(handleResponse).then(res => {
//         return res;
//     });
// }

/**
 * 查询deb包列表
 * @param {*} firmwareId
 * @param {*} offset
 * @param {*} limit
 * @returns
 */
function getFirmwareSoftwareList(firmwareId, offset, limit) {
  return fetch(`/v1/console/firmwares/${firmwareId}/debs?&offset=${offset || 1}&limit=${limit || 10}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  })
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function getFirmwareSoftwareDetail(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  };
  return fetch(`/v1/console/firmwares/softwares/${id}`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function addFirmwareSoftware(firmwareId, name, version, description) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      name: name || "",
      version: version || "",
      description: description || "",
    }),
  };
  return fetch(`/v1/console/firmwares/${firmwareId}/softwares`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function editFirmwareSoftware(id, name, version, description) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    body: JSON.stringify({
      name: name || "",
      version: version || "",
      description: description || "",
    }),
  };
  return fetch(`/v1/console/firmwares/softwares/${id}`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
function removeFirmwareSoftware(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  };
  return fetch(`/v1/console/firmwares/debs/${id}`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

/**
 * 上传deb包
 * @param {*} firmwareId
 * @returns
 */
function uploadDeb(firmwareId, data) {
  const requestOptions = {
    method: "POST",
    body: data,
    headers: {
      // 'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    // body: JSON.stringify({
    //     name: name || '',
    //     version: version || '',
    //     description: description || '',
    // })
  };
  return fetch(`/v1/console/firmwares/${firmwareId}/debs/upload`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
/**
 * 查询deb包列表
 * @param {*} firmwareId
 * @returns
 */
function getDebsList(firmwareId) {
  //FIXME:
  const requestOptions = {
    method: "GET",
    body: "", //????????FIXME:
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
  };
  return fetch(`/v1/console/firmwares/${firmwareId}/debs`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
/**
 *
 * @param {*} firmwareId
 * @returns
 */
function releaseFirmware(firmwareId) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${store.getState().authToken}`,
    },
    // body: JSON.stringify({
    //     name: name || '',
    //     version: version || '',
    //     description: description || '',
    // })
  };
  return fetch(`/v1/console/products/firmwares/${firmwareId}/release`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
